
import { combineReducers } from '@reduxjs/toolkit';
import userSlice from './userSlice';
import languageSlice from './languageSlice';


const rootReducer = combineReducers({
    user: userSlice,
    languages: languageSlice
})

export default rootReducer
