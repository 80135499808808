import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_turniket/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_turniket/layout/core'
import { MasterInit } from '../_turniket/layout/MasterInit'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_turniket/partials/layout/theme-mode/ThemeModeProvider'
import { ToastContainer } from 'react-toastify'
const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
