import { createRoot } from 'react-dom/client'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import { QueryClient, QueryClientProvider } from 'react-query'
import { TurniketI18nProvider } from './_turniket/i18n/Turniketi18n'
import './_turniket/assets/fonticon/fonticon.css'
import './_turniket/assets/keenicons/duotone/style.css'
import './_turniket/assets/keenicons/outline/style.css'
import './_turniket/assets/keenicons/solid/style.css'
import './_turniket/assets/sass/style.scss'
import './_turniket/assets/sass/plugins.scss'
import './_turniket/assets/sass/style.react.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from './redux-store/store';


setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root');

if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <TurniketI18nProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </TurniketI18nProvider>
        </QueryClientProvider>
      </PersistGate>
    </Provider>

  )
}
