import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    languages: [],
    currentLang: 'az'
}


const languages = createSlice({
    name: 'languagesSlice',
    initialState,
    reducers: {
        setLanguages: (state, action) => {
            return {
                ...state,
                languages: action.payload
            }
        },
        changeCurrentLang: (state, action) => {
            return {
                ...state,
                currentLang: action.payload
            }
        },
        removeLanguages: (state) => {
            return {
                ...state,
                languages: []
            }
        }
    }
})

export const { setLanguages, removeLanguages,changeCurrentLang } = languages.actions

export default languages.reducer

