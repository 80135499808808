import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {

  return (
    <>
      <AsideMenuItemWithSub
        to='/reports'
        title='Hesabatlar'
        fontIcon='bi-archive'
        icon='black-right'
      >
        <AsideMenuItem to='/reports/information/all-input-output-information' title='Bütün giriş-çıxış məlumatları' hasBullet={true} />
        <AsideMenuItem to='/reports/information/delay-reports' title='İşə gecikmə hesabatı' hasBullet={true} />
        <AsideMenuItem to='/reports/information/report-for-returning-late-from-dinner' title='Nahardan gec qayıtma üzrə hesabat' hasBullet={true} />
        <AsideMenuItem to='/reports/information/report-due-to-work-period' title='İş müddəti üzrə hesabat' hasBullet={true} />
        <AsideMenuItem to='/reports/information/list-of-the-working-group' title='İşçi heyətinin siyahısı' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem to='/languages' title='Dillər' hasBullet={true} />
      <AsideMenuItem to='/permissions' title='İcazələr' hasBullet={true} />
      <AsideMenuItem to='/roles' title='Rollar' hasBullet={true} />
      <AsideMenuItem to='/settings' title='Tənzimləmələr' hasBullet={true} />
      <AsideMenuItem to='/admins' title='Adminlər' hasBullet={true} />
    </>
  )
}
