import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage } from '../modules/auth'
import { App } from '../App'
import { useSelector } from 'react-redux'
const PUBLIC_URL = '/'
const AppRoutes = () => {

  const currentUser = useSelector(state => state.persistedReducer.user.user);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser?.id ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}

          {/* <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/dashboard' />} />
            <Route path='auth/*' element={<AuthPage />} />
            <Route path='auth/*' element={<Navigate to='/auth' />} />

          </> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
