import { createSlice } from "@reduxjs/toolkit";

export const initialState  = {
     user: null
}


const user = createSlice({
     name:'userSlice',
     initialState,
     reducers:{
         setUser: (state, action) => {
             return {
                 ...state,
                  user: action.payload
             }
         },
         removeUser:(state) => {
             return {
                 ...state,
                 user: null
             }
         }
     }
})

export const {setUser,removeUser} = user.actions

export default user.reducer

