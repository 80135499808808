import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { setLanguage } from '../../../i18n/Turniketi18n'
import { useSelector, useDispatch } from 'react-redux';
import { changeCurrentLang } from '../../../../redux-store/slices/languageSlice';


type Props = {
  languageMenuPlacement?: 'lert-start' | 'right-end'
}

const Languages: FC<Props> = ({ languageMenuPlacement = 'lert-start' }) => {

  const dispatch = useDispatch();
  const languages = useSelector((state: any) => state.persistedReducer.languages.languages);
  const currentLanguage = useSelector((state: any) => state.persistedReducer.languages.currentLang);

  return (
    <div
      className='menu-item px-5'
      data-kt-menu-trigger='hover'
      data-kt-menu-placement={languageMenuPlacement}
      data-kt-menu-flip='bottom'
    >
      <button className='menu-link border-0 outline-0 w-100 bg-transparent px-5'>
        <span className='menu-title d-flex justify-content-between position-relative'>
          Dil
          <span className='fs-8 d-flex align-items-center gap-2 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={toAbsoluteUrl(`/media/flags/${currentLanguage === 'az' ? 'azerbaijan.svg' : currentLanguage === 'en' ? 'england.svg' : currentLanguage === 'ru' ? 'russia.svg' : 'azerbaijan.svg'}`)}
              alt='flag'
            />
            <span className='text-uppercase'>{currentLanguage}</span>
          </span>
        </span>
      </button>

      <div className='menu-sub menu-sub-dropdown w-175px py-4'>
        {languages.length > 0 && languages.map((l: any) => (
          <div
            className='menu-item px-3'
            key={l?.id}
            onClick={() => {

              setLanguage(l?.code);
              dispatch(changeCurrentLang(l?.code))
            }}
          >
            <button
              className={clsx('menu-link d-flex px-5 border-0 outline-none bg-transparent', { active: l?.code === currentLanguage })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={toAbsoluteUrl(`/media/flags/${l?.code === 'az' ? 'azerbaijan.svg' : l?.code === 'en' ? 'england.svg' : l?.code === 'ru' ? 'russia.svg' : 'azerbaijan.svg'}`)} alt='flag' />
              </span>
              {l?.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}

export { Languages }
