import { useEffect } from 'react';
import { PageTitle } from '../../../_turniket/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_turniket/partials/widgets';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { setLanguages } from '../../../redux-store/slices/languageSlice';
import { removeUser } from '../../../redux-store/slices/userSlice';
import { toast } from 'react-toastify';

const DashboardPage = () => (
  <>
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div>
      {/*end::Col*/}
    </div>
    {/*end::Row*/}

    <TablesWidget9 className='mb-5 mb-xl-10' />

    {/*begin::Row*/}
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xxl-6'>
        <ListsWidget5 className='card-xl-stretch mb-xl-10' />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xxl-6'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} />
      </div>
      {/*end::Col*/}
    </div>
  </>
)

const DashboardWrapper = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const languages = useSelector((state: any) => state.persistedReducer.languages.languages)

  const currentUser = useSelector((state: any) => state.persistedReducer.user.user);
  const API_URL = process.env.REACT_APP_BASE_URL;

  const getAllLanguages = async () => {
    try {
      const result: any = await axios.get(`${API_URL}/api/languages`);

      if (result.status === 200) {
        dispatch(setLanguages(result?.data?.languages))
      } else {
        throw new Error(result?.data)
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('token');
        dispatch(removeUser());
        navigate('/auth/login');
      } else {
        toast.error(error?.response?.data?.message);
      }
    }
  }
  useEffect(() => {
    if (languages.length === 0) {
      getAllLanguages();
    }
  }, [languages])
  return (
    <>
      <PageTitle description='You’ve got 24 New Sales' breadcrumbs={[]}>
        Hello{currentUser?.name}
      </PageTitle>
      <DashboardPage />
    </>
  )
}

export { DashboardWrapper }
